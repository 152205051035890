<template>
  <div class="contributor">
    <div class="tabs">
      <SectionTabs :tabs="tab_component" />
    </div>
    <div class="contributor-top">
      <div class="contributor-top-select">
        <h3>{{ title_component.subtitle }}</h3>
        <div class="select-container" :class="open_indicadores_select_list && 'active'">
          <div class="select-btn" @click="openSelectIndicadores">
            <div class="item">
              {{ indicadores_selected }}
            </div>
            <div class="icon">
              <font-awesome-icon :icon="open_indicadores_select_list ? 'angle-up' : 'angle-down'">
              </font-awesome-icon>
            </div>
          </div>
          <div class="select-list" v-if="open_indicadores_select_list">
            <div v-for="(indi, k) in indicadores_list" :key="k" class="list-item" @click="selectIndicador(indi)">
              {{ indi.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="ranking-top-filter">
        <div class="title">Filtrar por período:</div>
        <div class="calendar-container d-flex" v-click-outside="handleCalendar">
          <div class="calendar" :class="calendar_side" v-if="show_calendar">
            <v-calendar color="blue" :max-date="max_date" :min-date="min_date" :attributes="attributes"
              v-model="date_init" @dayclick="onDayClick" />
            <div class="border-arrow" :class="calendar_side">
              <div class="arrow"></div>
            </div>
          </div>
          <div class="start" @click="openCalendar('start-date')">
            <span>{{ fecha_inicio }}</span>
            <div class="icon">
              <img :src="img_calendar" alt="" />
            </div>
          </div>
          <div class="end unactive" v-if="fecha_inicio == 'Inicio'">
            <span>{{ fecha_fin }}</span>

            <div class="icon">
              <img :src="img_calendar" alt="" />
            </div>
          </div>
          <div class="end" v-if="fecha_inicio != 'Inicio'" @click="openCalendar('end-date')">
            <span>{{ fecha_fin }}</span>
            <div class="icon">
              <img :src="img_calendar" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contributor-container">
      <div class="top-cont" v-show="user_selected == null">
        <!-- Buscador de usuarios -->
        <div class="header">
          <div class="header-title">Realiza una búsqueda</div>
          <div class="searcher">
            <div class="searcher-container" :class="user_list.length > 0 && 'active'">
              <input type="text" placeholder="Nombre Usuario" @keydown.enter="getUsers" v-model="user_name" />
              <div class="icon" @click="getUsers">
                <font-awesome-icon icon="search"></font-awesome-icon>
              </div>
            </div>
          </div>
        </div>
        <!-- list -->
        <div class="top-cont-body">
          <div class="list">
            <div class="item" v-for="(user, k) in user_list" :key="k" @click="getRec(user)">
              <div class="item-left">
                <div class="user-img">
                  <img :src="user_default_img" v-if="!user.url_imagen" alt="" />
                  <img :src="user.url_imagen" v-if="user.url_imagen" alt="" />
                </div>
                <div class="user-info">
                  <div class="user-name">
                    {{ user.primer_nombre + " " + user.apellido_paterno }}
                  </div>
                  <div class="user-position">
                    {{
                    user.cargo.nombre_cargo == null ||
                    user.cargo.nombre_cargo == undefined
                    ? "Cargo"
                    : user.cargo.nombre_cargo
                    }}
                  </div>
                  <div class="user-total">
                    <img :src="img" alt="" />Total:
                    {{ user.total_reconocimientos }}
                  </div>
                </div>
              </div>
              <div class="item-right">
                <div class="recognitions-details">
                  <div class="detail" v-for="(re, i) in valorFormat(user.reconocimientos)" :key="i">
                    <div class="detail-title" :style="`color: #${value_colors[re.nombre_valor]};`">
                      {{ re.nombre_valor }}:
                    </div>
                    <div class="num">
                      {{ re.total }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Boton 'Volver' -->
      <div class="go-back" v-show="user_selected !== null" @click="goBack">
        <span class="icon">
          <font-awesome-icon icon="angle-left"></font-awesome-icon>
        </span>
        <span> Volver</span>
      </div>
      <!-- Sin resultados -->
      <div class="no-results" v-show="no_results">
        <h3>No se encontraron resultados</h3>
      </div>
      <!-- Detalle de reconocimientos -->
      <div class="user-detail" v-if="user_selected !== null">
        <div class="user-detail-container">
          <div class="user-detail-container-left">
            <div class="user-img">
              <img :src="user_default_img" alt="" />
            </div>
            <div class="user-info">
              <div class="user-name">
                {{
                user_selected.primer_nombre +
                " " +
                user_selected.apellido_paterno
                }}
              </div>
              <div class="user-position">
                {{ user_selected.sucursal[0] }}
              </div>
              <div class="user-total">
                <img :src="img" alt="" />Total:
                {{ user_selected.rec.length }}
              </div>
            </div>
          </div>
          <div class="user-detail-container-right">
            <div class="recognitions-details">
              <div class="detail" v-for="(val, i) in values" :key="i">
                <div class="title" :style="`color: #${value_colors[val.nombre_valor]};`">
                  {{ val.nombre_valor }}:
                </div>
                <div class="num">
                  {{
                  user_selected.values_count[val.nombre_valor] != undefined
                  ? user_selected.values_count[val.nombre_valor].count
                  : 0
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="switch">
          <div class="switch-container">
            <div class="left" :class="switch_selected == 'received' && 'active'" @click="switchItemSelect('received')">
              Recibidos
            </div>
            <div class="right" :class="switch_selected == 'sended' && 'active'" @click="switchItemSelect('sended')">
              Enviados
            </div>
          </div>
        </div>
        <ul class="recognition-list" v-show="switch_selected == 'received'">
          <li class="recognition-list-item" v-for="(re, i) in user_selected.rec" :key="i">
            <div class="recognition-list-item-left">
              <div class="user-img">
                <img :src="user_default_img" alt="" />
              </div>
              <div class="user-info">
                <div class="name">
                  {{ re.reconocedor[0].primer_nombre }}
                  {{ re.reconocedor[0].apellido_paterno }}
                </div>
                <span>
                  ha
                  <img :src="img" alt="" />
                  por
                  <b :style="`color: #${
                      value_colors[
                        re.valor_comportamiento[0].valor.nombre_valor
                      ]
                    };`">{{ re.valor_comportamiento[0].valor.nombre_valor }}</b>
                </span>
                el
                {{ dateNewFormat(re.fecha_creacion, 2) }}
              </div>
            </div>
            <div class="recognition-list-item-right">
              <div class="details">Ver detalle</div>
            </div>
          </li>
        </ul>
        <ul class="recognition-list" v-show="switch_selected == 'sended'">
          <li class="recognition-list-item" v-for="(re, i) in user_selected.reconocimientos_enviados" :key="i">
            <div class="recognition-list-item-left">
              <div class="user-img">
                <img :src="user_default_img" alt="" />
              </div>
              <div class="user-info">
                <span>
                  Ha
                  <img :src="img" alt="" />
                  a
                  <div class="name">
                    {{ re.reconocidos[0].primer_nombre }}
                    {{ re.reconocidos[0].apellido_paterno }}
                  </div>
                  por
                  <b :style="`color: #${
                      value_colors[
                        re.valor_comportamiento[0].valor.nombre_valor
                      ]
                    };`">{{ re.valor_comportamiento[0].valor.nombre_valor }}</b>
                </span>
                el
                {{ dateNewFormat(re.fecha_creacion, 2) }}
              </div>
            </div>
            <div class="recognition-list-item-right">
              <div class="details">Ver detalle</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Spinner v-if="show_spinner" />
  </div>
</template>

<script>
import SectionTabs from "../Section/SectionTabs.vue";
import gql from "graphql-tag";
import { GET_VALORES } from "@/apollo/queries";
import Spinner from "../Spinner.vue";
/* 
    $id_empresa: String!
    $nombre_buscar: String!
    $id_gerencia: String! */
const GET_USERS = gql`
  query contactos(
    $nombre_usuario: String!
    $id_empresa: String!
    $fecha_inicio: String!
    $fecha_final: String!
  ) {
    reconocidosYearMonthReconocimientosName(
      nombre_usuario: $nombre_usuario
      id_empresa: $id_empresa
      fecha_inicio: $fecha_inicio
      fecha_final: $fecha_final
    ) {
      id_usuario
      primer_nombre
      segundo_nombre
      apellido_paterno
      apellido_materno
      rut_usuario
      dv_usuario
      imagen_perfil
      genero
      total_reconocimientos
      cargo {
        id_cargo
        nombre_cargo
      }
      sucursal {
        id_sucursal
        nombre_sucursal
      }
      gerencia {
        id_gerencia
        nombre_gerencia
        bussiness_unit
        unit_number
      }
      reconocimientos {
        id_reconocimiento
        url_imagen
        comentario_reconocimiento
        fecha_creacion
        visto
        nombre_grupo
        valor_comportamiento {
          valor {
            id_valor
            nombre_valor
            color
            id_estado_fk
          }
        }
      }
      reconocimientos_enviados {
        id_reconocimiento
        url_imagen
        comentario_reconocimiento
        fecha_creacion
        visto
        nombre_grupo
        reconocidos {
          id_usuario
          primer_nombre
          segundo_nombre
          apellido_paterno
          apellido_materno
          rut_usuario
          dv_usuario
        }
        valor_comportamiento {
          valor {
            id_valor
            nombre_valor
            color
            id_estado_fk
          }
        }
      }
      reconocimientos_valor {
        id_valor
        nombre_valor
        cantidad
      }
    }
  }
`;

const GET_VALS = gql`
  query valoresReconocimientos($id_empresa: String!) {
    valoresReconocimientos(id_empresa: $id_empresa) {
      id_valor
      nombre_valor
      id_estado_fk
    }
  }
`;

const GET_RECO = gql`
  query getReconocimientos(
    $id_empresa: String!
    $id_usuario: String!
    $tipo_reconocimiento: Int!
  ) {
    reconocimientos(
      id_empresa: $id_empresa
      id_usuario: $id_usuario
      tipo_reconocimiento: $tipo_reconocimiento
    ) {
      id_reconocimiento
      url_imagen
      comentario_reconocimiento
      fecha_creacion
      visto

      reconocedor {
        primer_nombre
        apellido_paterno
      }
      valor_comportamiento {
        valor {
          id_valor
          nombre_valor
        }
        comportamiento {
          nombre_comportamiento
        }
      }
    }
  }
`;

const GET_GERENCIAS = gql`
  query getGerencias($id_empresa: String!) {
    gerencias(id_empresa: $id_empresa) {
      id_gerencia
      nombre_gerencia
    }
  }
`;

export default {
  components: {
    SectionTabs,
    Spinner,
  },
  data() {
    return {
      show_spinner: false,
      img: require("@/assets/img/reconoze/ball-star.svg"),
      tab_component: [
        {
          name: "Dashboard",
          route: "recognize-Dashboard",
        },
        {
          name: "Indicadores",
          route: "recognize-contributor",
        },
      ],
      indicadores_list: [
        { name: "Año", route: "recognize-year" },
        { name: "Gerencia", route: "recognize-management" },
        { name: "Planta", route: "recognize-age" },
        { name: "Top 20", route: "recognize-ranking" },
        { name: "Consultas por colaborador", route: "recognize-contributor" },
        { name: "Detalles de reconocimiento", route: "recognize-reports" },
      ],
      indicadores_selected: "Consultas por colaborador",
      open_indicadores_select_list: false,
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      title_component: {
        title: "Reconoce",
        subtitle:
          "¡Conoce el impacto de Reconoce! Podrás revisar cifras anuales, por gerencia, top 20, lugar de trabajo y colaborador.",
      },
      open_select_list: "",
      gerencia_list: [{ id_gerencia: "0", nombre_gerencia: "Todas" }],
      gerencia_selected: { id_gerencia: "0", nombre_gerencia: "Todas" },
      user_list: [],
      initial_user_list: [],
      user_selected: null,
      user_name: "",
      no_results: false,
      switch_selected: "received",
      user_default_img: require("@/assets/img/user_default_img.svg"),
      values: [],
      colors: ["#357BF3", "#23BE61", "#F26F28", "#F6C700"],
      show_calendar: false,
      calendar_side: "",
      fecha_inicio: "Inicio",
      fecha_fin: "Término",
      max_date: "",
      min_date: "",
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      valores: [],
      value_colors: [],
    };
  },
  mounted() {
    this.fecha_fin = this.dateNewFormat(new Date(), 1);
    this.fecha_inicio = this.dateNewFormat(this.addDays(new Date(), -30), 1);
    this.getGerencias(this.id_empresa);
    this.getValues(this.id_empresa);
    this.getValores();
 
  },
  watch: {
    fecha_inicio() {
      this.getUsers();
    },
    fecha_fin() {
      this.getUsers();
    },
  },
  computed: {
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
  methods: {
    getValores() {
      this.value_colors = {};
      this.$apollo
        .query({
          query: GET_VALORES,
          variables: {
            id_empresa: this.id_empresa,
          },
        })
        .then((res) => {
          var resp = res.data.valoresReconocimientos;
          this.valores = resp;
          //console.log("colors", resp);
          resp.forEach((el) => {
            this.value_colors[el.nombre_valor] = el.color;
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    handleUserImage(img) {
      if (img !== null && img !== "" && img !== undefined) {
        return img;
      } else {
        return this.user_default_img;
      }
    },
    getGerencias(id) {
      this.gerencia_list = [{ id_gerencia: "0", nombre_gerencia: "Todas" }];
      this.$apollo
        .query({
          query: GET_GERENCIAS,
          variables: {
            id_empresa: id,
          },
        })
        .then((res) => {
          var resp = res.data.gerencias;
          //console.log(resp);
          resp.forEach((element) => this.gerencia_list.push(element));
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getValues(id) {
      this.$apollo
        .query({
          query: GET_VALS,
          variables: {
            id_empresa: id,
          },
        })
        .then((res) => {
          var resp = res.data.valoresReconocimientos;
          this.values = resp;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    selectGerencia(gerencia) {
      this.gerencia_selected = gerencia;
      setTimeout(() => {
        this.open_select_list = "";
      }, 400);
    },
   async getUsers() {
      
      this.show_spinner = true;
      let is_initial = 0;
      this.gerencia_list = [
        {
          id_gerencia: "0",
          nombre_gerencia: "Todas",
        },
      ];
      if(this.initial_user_list.length < 1){
        is_initial = 1;
      }
      if(is_initial != 1 && this.user_name != ''  ){
        if(this.user_name.length > 3){
         await this.getUserList().then(res=>{
             this.initial_user_list = res;
              this.user_list = res;
              this.show_spinner = false;
          }).catch(err =>{
            this.show_spinner = false;
            throw err;
          })
        }
      }else{
       await this.getUserList().then(res=>{
             this.initial_user_list = res;
              this.user_list = res;
              this.show_spinner = false;
          }).catch(err =>{
            this.show_spinner = false;
            throw err;
          })
      }
    },
    async getUserList(){  
      let response = [];
     await this.$apollo
        .query({
          query: GET_USERS,
          variables: {
            nombre_usuario: this.user_name,
            id_empresa: this.id_empresa,
            fecha_inicio: this.fecha_inicio,
            fecha_final: this.fecha_fin,
          },
        })
        .then((res) => {
          response = res.data.reconocidosYearMonthReconocimientosName;
        })
        .catch((err) => {
          console.log("err", err);
        });
        return new Promise((resolve)=>{
          resolve(response)
        })
    },
    filternByName() {
      var search = this.user_name.toLowerCase();
      if (search.length <= 2) {
        this.user_list = this.initial_user_list;
      } else {
        let search_result = this.initial_user_list.filter(
          (user) =>
            user.primer_nombre.toLowerCase().includes(search) ||
            user.apellido_paterno.toLowerCase().includes(search)
        );
        if (search_result.length > 0) {
          this.user_list = search_result;
        //   search_result.forEach((item) => {
        //   if (item.id_usuario !== this.user.id_usuario) {
        //     this.users_search_list.push(item);
        //   }
        // }
        } else {
          this.getUsers();
        }
      }
    },
    filternByNameDelete() {
      var search = this.user_name;
      if (search.length <= 2) {
        this.getUsers()
      }
    },
    getRec(user) {
      this.$apollo
        .query({
          query: GET_RECO,
          variables: {
            id_empresa: this.id_empresa,
            id_usuario: user.id_usuario,
            tipo_reconocimiento: 1,
          },
        })
        .then((res) => {
          var resp = res.data.reconocimientos;
          var r = [];
          resp.forEach(function (e) {
            r.push(e);
          });
          //console.log("re", r);
          this.openUserDetail(user, r);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    openUserDetail(user, res) {
      var arr = [];
      res.forEach(function (e) {
        arr.push(e.valor_comportamiento[0].valor.nombre_valor);
      });
      var repetidos = [];
      arr.forEach(function (val) {
        repetidos[val] = {
          key: val,
          count:
            (repetidos[val] != undefined ? repetidos[val].count : 0 || 0) + 1,
        };
      });

      var us = user;
      //console.log("user", us);
      us.reconocimientos_length = res.length;
      us.values_count = repetidos;
      us.rec = res;
      this.user_selected = user;
      //console.log(this.user_selected);
      //this.user_list = [];
      this.user_name = "";
    },
    switchItemSelect(item) {
      this.switch_selected = item;
    },
    goBack() {
      this.user_selected = null;
    },
    openSelect(item) {
      if (item == this.open_select_list) {
        this.open_select_list = "";
      } else {
        this.open_select_list = item;
      }
    },

    openSelectIndicadores() {
      this.open_indicadores_select_list = !this.open_indicadores_select_list;
    },
    selectIndicador(indi) {
      if (this.indicadores_selected != indi.name) {
        this.$router.push({ name: indi.route });
        this.indicadores_selected = indi.name;
      }
      this.open_indicadores_select_list = false;
    },
    valorFormat(element) {
      let counter = [];
      //console.log("for", element);
      if (
        element != undefined &&
        element != null &&
        element != [] &&
        element != ""
      ) {
        //console.log("ele",element)
        element.forEach((el) => {
          let is_in = counter.find(
            (ob) =>
              ob.nombre_valor == el.valor_comportamiento[0].valor.nombre_valor
          );
          //console.log(is_in);
          if (is_in != undefined) {
            let i = counter.findIndex(
              (ob) =>
                ob.nombre_valor == el.valor_comportamiento[0].valor.nombre_valor
            );
            //console.log(counter)
            var new_total = counter[i].total + 1;
            counter.splice(i, 1, {
              nombre_valor: counter[i].nombre_valor,
              total: new_total,
            });
          } else {
            counter.push({
              nombre_valor: el.valor_comportamiento[0].valor.nombre_valor,
              total: 1,
            });
          }
        });
      }

      return counter;
    },

    openCalendar(side) {
      this.show_calendar = true;
      this.calendar_side = side;
      if (this.calendar_side == "start-date") {
        this.min_date = "";
        this.date_init =
          this.fecha_inicio != "Inicio" ? new Date(this.fecha_inicio) : "";
        this.max_date = this.fecha_fin == "Término" ? "" : this.fecha_fin;
        this.min_date = "";
      } else {
        var min = new Date(this.fecha_inicio);
        this.min_date = min.setDate(min.getDate() + 1);
        this.max_date = "";
        this.min_date = this.fecha_inicio == "Inicio" ? "" : this.fecha_inicio;
      }
    },
    onDayClick(day) {
      if (this.calendar_side == "start-date") {
        this.fecha_inicio = day.id;
      } else {
        this.fecha_fin = day.id;
      }
      if (this.fecha_inicio != "Inicio" || this.fecha_fin != "Término") {
        this.getUsers();
      }
      this.show_calendar = false;
    },
    handleCalendar() {
      // console.log("click outside calendar");
      this.show_calendar = false;
    },
    addDays(date, days) {
      var result = date;
      result.setDate(result.getDate() + days);
      return result;
    },
    dateNewFormat(date, type) {
      if (type == 1) {
        var this_day = date;
        var day = this_day.getDate();
        var month = this_day.getMonth();

        return `${this_day.getFullYear()}-${
          month + 1 < 10 ? `0${month + 1}` : month + 1
        }-${day + 1 < 10 ? `0${day + 1}` : day + 1}`;
      }
      if (type == 2) {
        var date_day = new Date(date);
        var month_ = date_day.getMonth();
        //console.log("fecha",`${date_day.getDate()}/${month_ + 1 < 10 ? `0${month_+ 1}` : month_+ 1}/${date_day.getFullYear()}`)
        return `${date_day.getDate()}/${
          month_ + 1 < 10 ? `0${month_ + 1}` : month_ + 1
        }/${date_day.getFullYear()}`;
      }
    },
  },
};
</script>
